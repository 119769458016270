<template>

  <div id="hello">
    <router-link :to="{ name: 'Home' }"  v-if="currentRouteName != 'Home'">
      <img src="tov.svg" id="logo" />
    </router-link>
    <span v-else>
      <img src="tov.svg" id="logo" />
    </span>
  </div>

  <div id="content">
    <router-view />
  </div>

  <div id="bottom">
    2023 &copy; Tov.games, <a href="https://lis.dev/">Aleksandr Lis</a>

    <div id="social">
      <a href="mailto:hello@tov.games">
        <at-icon :size="30" />
      </a>
      <a href="https://tovgames.itch.io/">
        <img src="itchio.svg" width="28" height="28" />
      </a>
      <!-- <a href="https://instagram.com/tov.games">
        <instagram-icon :size="30" />
      </a> -->
      <a href="https://twitter.com/tov_games">
        <twitter-icon :size="30" />
      </a>
    </div>
  </div>
</template>

<script>
import AtIcon from 'vue-material-design-icons/At.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';
import SteamIcon from 'vue-material-design-icons/Steam.vue';

export default {
  name: 'App',
  components: {
    AtIcon,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    SteamIcon,
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    },
  },
}
</script>

<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}
html {
  background: transparent;
}
body {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-size: 17px;
  background-color: #F5E9CF;
  color: #4D455D;
}
h1, h2 {
  font-weight: 300;
  margin-bottom: 20px;
}

h1 {
  font-size: 50px;
}
h2 {
  font-size: 40px;
}
a {
  color: #7DB9B6;
}
p {
  margin-bottom: 20px;
  line-height: 140%;
}

div#hello {
  width: 100%;
  padding: 120px;
  img#logo {
    width: 400px;
    height: 100px;
  }
}

div#content {
  width: 760px;
  margin: auto;
  text-align: left;
}
div#bottom {
  padding-bottom: 120px;
  a {
    color: #4D455D;
  }
  div#social {
    padding-top: 20px;
    a {
      margin: 0 4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 20px;
  }
  div#content {
    width: 100%;
    padding: 0 40px;
  }
}

</style>
